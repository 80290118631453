import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Box, Grid, Link, Paper } from '@material-ui/core';
import SearchForm from './SearchForm';
import logo from './logo.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  paper: {
    padding: theme.spacing(3), // Added padding here
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: theme.spacing(3)
  },
  logo: {
    height: 80
  }
}));

export default function App() {
  const classes = useStyles();

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} className={classes.paper}> {/* Applied the paper class here */}
        <Grid container spacing={3} className={classes.mainGrid}>
          <Grid item xs={12} className={classes.header}>
            <img src={logo} alt="Logo" className={classes.logo} />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" component="h1" gutterBottom align="center">
              We have moved!
            </Typography>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="body1" align="center">
            This tool is now part of the TelecomsXChange ChatGPT app. Access the GPT{' '}
            <Link href="https://chat.openai.com/g/g-tEGI40th6-telecomsxchange-tcxc" target="_blank" rel="noopener noreferrer">
              here
            </Link>.
        </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" style={{ fontWeight: 'bold' }}>
              Thank you!
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Box pb={3}>
        <Copyright />
      </Box>
    </Container>
  );
}
